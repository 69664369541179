<template>
    <div class="VideoBackend">
        <input type="text" placeholder="File id" v-model="inputText">
        <button v-on:click="fetchVideo()">Recuperar stream</button>
        <video id="videoBackend" src="" controls></video>
    </div>
      
  </template>
  
  <script>
    import Swal from 'sweetalert2'
    import { mapGetters } from 'vuex';
    import store from '@/store';
  
    export default {
      name: 'VideoBackend',
      data(){
        return{
            inputText:'',
        }
      },
      methods:{
        fetchVideo(){
          const beforeDate = new Date()
          const info = {
            text:'4461d2dc-dddf-49f3-92e4-c6de0e12345e'
          }
            store.dispatch('CALL_VIDEO_BACKEND_URL',info)
            .then((data) =>{
              //const videoUrl = URL.createObjectURL(data);
              console.log(data)
                const videoElement = document.querySelector('#videoBackend');
                videoElement.src = data
                const afterDate = new Date()

                console.log(afterDate - beforeDate)
                /*videoElement.addEventListener('loadedmetadata', () => {
                  const mediaStream = videoElement.captureStream();
                  const anotherVideoElement = document.getElementById('anotherVideo');
                  anotherVideoElement.srcObject = mediaStream;
                })*/
            })
        }
      }
    };
  </script>
  